





























import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { IOption } from "@/models/interface-v2/common.interface";
import { ListCompanyBank, ResponseListCompanyBank } from "@/models/interface-v2/company-bank.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import Vue from "vue";
export default Vue.extend({
  name: "CSelectCompanyBank",
  mixins: [
    MNotificationVue
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    },
    propSearchBy: {
      type: String,
      default: undefined,
    },
  },
  data() {
    this.searchProduct = debounceProcess(this.searchProduct, 300);
    return {
      loading: false,
      dtProducts: {} as ResponseListCompanyBank,
      optProducts: [] as IOption<ListCompanyBank>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: "active~true"
      },
    };
  },
  created() {
    if (this.propSearchBy) this.queryParams.search = `active~true_AND_${this.propSearchBy}`;
    this.getList(this.queryParams);
  },
  methods: {
    getListMasterProducts(params: RequestQueryParamsModel): Promise<ResponseListCompanyBank> {
      return masterServices.listCompanyBank(params);
    },
    async searchProduct(search = ""): Promise<void> {
      try {
        this.loading = true;
        const searchBy = ["active~true"];
        if (search) searchBy.push(`bankAccName~*${search}*_OR_bankBranch~*${search}*_OR_bankName~*${search}*`);
        if (this.propSearchBy) searchBy.push(this.propSearchBy);
        this.queryParams.search = searchBy.join("_AND_");
        this.queryParams.page = 0;
        this.optProducts = [];
        this.getList(this.queryParams);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    onSelect(e: string, meta: ListCompanyBank): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    popupScroll(e): void {
      if ((this.dtProducts.totalPages - 1) === this.dtProducts.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterProducts(params);
        const { optProducts } = this;
        const opts = res.data.map(x => ({ key: `${x.bankName} - ${x.bankBranch} - ${x.bankAccName}`, value: x.id, meta: x }));
        this.optProducts = [...optProducts, ...opts];
        this.dtProducts = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    }
  }
});
